import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import CancelledTag from '../../../pages/_components/CancelledTag';
import CompletedTag from '../../../pages/_components/CompletedTag';
import ExpiredTag from '../../../pages/_components/ExpiredTag';
import DateRange from './DateRange';
import Visibility from './Visibility';
import Creator from './Creator';
import Site from './Site';
import ShareLink from './ShareLink';
import Partners from '../../../pages/projects/Row/Partners';
import CreatorPhone from './CreatorPhone';
import CreatorEmail from './CreatorEmail';

export default function Header(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Project
          </div>
          <div className="flex space-x-2">
            <ExpiredTag resource={project} />
            <CancelledTag resource={project} />
            <CompletedTag resource={project} />
          </div>
        </div>
        <div className="text-3xl font-bold text-cs-gray-700">
          {project.data.title || activity.data.name || '...'}
        </div>
        <div className="text-cs-gray-500">
          {project.data.desiredOutcome}
        </div>
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-x-4 gap-y-2">
            <DateRange project={project} />
            <Creator project={project} />
            <CreatorPhone project={project} />
            <CreatorEmail project={project} />
            <Site project={project} />
            <Visibility project={project} />
            <ShareLink project={project} />
          </div>
          <Partners project={project} />
        </div>
      </div>
    </div>
  );
};
